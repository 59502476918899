<template>
  <div>
    <div v-if="$route.params.key">
      <v-progress-circular
        v-show="loading"
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div v-if="error.length" class="error--text text-h5">{{ error }}</div>
    </div>
    <v-card width="400px" v-else>
      <v-card-title>Confirmez votre adresse email</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        Un email avec un lien de vérification a été envoyé
        <span v-if="$route.query.email">à {{ $route.query.email }}</span
        >. Veuillez cliquer sur ce lien pour valider votre adresse email.
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          class="ml-auto"
          @click="sendEmailAgain"
          :loading="loading"
          :disabled="!canSend"
          >Renvoyer l'email</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'

export default {
  name: 'ConfirmEmail',
  data() {
    return {
      email: null,
      canSend: true,
      loading: false,
      error: '',
    }
  },
  methods: {
    sendEmailAgain() {
      this.loading = true
      requestService
        .post('api/user/send-email-verif', {
          email: this.$route.query.email,
        })
        .then(response => {
          if (!response.success) {
            this.$store.dispatch('alert/error', response.message)
          } else {
            this.$store.dispatch(
              'alert/success',
              'Un email de vérification a été envoyé',
            )
            this.canSend = false
            setTimeout(() => (this.canSend = true), 30000)
          }
        })
        .finally(() => (this.loading = false))
    },
    checkKey() {
      this.loading = true
      requestService
        .post('api/user/verify-email', {
          key: this.$route.params.key,
        })
        .then(response => {
          if (!response.success) {
            this.error = response.message
          } else {
            this.$store.dispatch(
              'alert/success',
              'Votre adresse email a été vérifiée',
            )
            this.$router.push({ name: 'Login' })
          }
        })
        .finally(() => (this.loading = false))
    },
  },
  created() {
    if (this.$route.params.key) {
      this.checkKey()
    }
    this.canSend = this.$route.query.email
  },
}
</script>

<style scoped></style>
